<template>
  <!-- Edit groups -->
  <b-modal
    id="edit-groups"
    ref="edit-groups"
    centered
    title="Edit Groups"
    ok-title="Save Changes"
    ok-only
    no-close-on-backdrop
    @hidden="resetModal"
    @ok="setGroups"
  >
    <b-row>
      <b-col
        sm="12"
        class="mb-1"
      >
        <b-form-group
          label="Group"
        >
          <v-select
            v-model="selectedGroups"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="availableGroupsList"
            multiple
            label="name"
            value="id"
            placeholder="Select Group"
          >
            <template
              #option="{ name, type }"
            >
              <div class="d-flex justify-content-between align-items-center">
                <span>{{ name }}</span> <em class="mr-2">{{ type }}</em>
              </div>
            </template>
          </v-select>
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import useEditGroups from '@/views/components/edit-user-groups/edit-groups-modal/useEditGroups'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,

    vSelect,
  },
  props: {
    groupsList: {
      type: Array,
      default: () => [],
    },
    sessionsList: {
      type: Array,
      default: () => [],
    },
    userId: {
      type: Number,
      required: true,
    },
    programId: {
      type: String,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      selectedGroups: [...this.groupsList],
      availableGroupsList: [],
    }
  },
  watch: {
    groupsList(val) {
      this.selectedGroups = [...val]
    },
  },
  async created() {
    this.setAvaliableGroups()
  },
  setup() {
    const {
      fetchGroups,
      updateUserGroups,
    } = useEditGroups()

    return {
      fetchGroups,
      updateUserGroups,
    }
  },
  methods: {
    async setAvaliableGroups() {
      const queryParams = {
        exceptProgramType: 'Program',
        programId: this.programId,
        sessionId: this.sessionsList.map(session => session.id),
      }
      this.availableGroupsList = await this.fetchGroups(queryParams).then(res => res)
    },
    resetModal() {
      this.selectedGroups = [...this.groupsList]
    },
    async setGroups() {
      const queryParams = {
        groups_id: this.selectedGroups.map(group => group.id),
        application_id: this.applicationId,
      }

      await this.updateUserGroups(this.userId, queryParams)
        .then(() => {
          this.$bvModal.hide('edit-groups')
          this.$emit('updateGroups')
        })
    },
  },
}
</script>
