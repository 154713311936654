<template>
  <b-modal
    id="select-payment-contract"
    ref="select-payment-contract"
    centered
    title="Select Payment Contract"
    ok-title="Select"
    ok-only
    no-close-on-backdrop
    @hidden="resetModal"
    @show="setModalData()"
    @ok="applyChanges"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <b-col
          sm="12"
        >
          <b-form-group
            label="Payment Contracts"
          >
            <b-form-radio-group
              v-model="selectedContract"
              class="mt-1"
              stacked
            >
              <b-form-radio
                v-for="paymentContract in paymentContractsList"
                :key="paymentContract.id"
                :value="paymentContract.id"
                class="mb-1"
              >
                {{ paymentContract.name }}
              </b-form-radio>
            </b-form-radio-group>
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormRadioGroup,
  BFormRadio,
  BOverlay,
} from 'bootstrap-vue'

import usePaymentContractsSetting from '@/views/admin/payment-contracts/usePaymentContractsSetting'
import usePaymentContract from '@/views/components/application-payment-contract-v2/usePaymentContract'

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormRadioGroup,
    BFormRadio,
    BOverlay,
  },
  props: {
    paymentContractId: {
      type: [String, Number],
      required: true,
    },
    applicationId: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      paymentContractsList: [],
      selectedContract: null,
    }
  },
  setup() {
    const { fetchPaymentContractList } = usePaymentContractsSetting()
    const { updateApplicationPaymentContract } = usePaymentContract()

    return {
      fetchPaymentContractList,
      updateApplicationPaymentContract,
    }
  },
  methods: {
    async setModalData() {
      this.isLoading = true
      this.paymentContractsList = await this.fetchPaymentContractList()
      this.selectedContract = this.paymentContractId
      this.isLoading = false
    },
    resetModal() {
      this.isLoading = false
      this.paymentContractsList = []
      this.selectedContract = null
    },
    async applyChanges(bvModalEvt) {
      bvModalEvt.preventDefault()

      const queryParams = {
        applicationId: this.applicationId,
        contractId: this.selectedContract,
      }

      await this.updateApplicationPaymentContract(queryParams).then(() => {
        this.$emit('selectNewContract')
        this.$bvModal.hide('select-payment-contract')
      })
    },
  },
}
</script>
