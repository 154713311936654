<template>

  <!-- Table Apailable PPA Card -->
  <b-card
    no-body
  >
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        Available PPAs
      </h4>
    </template>

    <b-table
      :items="items"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :busy="isLoading"
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Loader -->
      <template #table-busy>
        <TableSpinner />
      </template>

      <!-- Column: value -->
      <template #cell(value)="data">
        <span v-if="isArray(data.item.value)">{{ data.item.value | toString }}</span>
        <span v-else-if="isBase64(data.item.value)">
          <img
            :src="data.item.value"
            alt="signature"
          >
        </span>
        <span v-else>{{ data.item.value }}</span>
      </template>
    </b-table>
  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BCard,
  BTable,
} from 'bootstrap-vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'

import useAvailablePpaList from './useAvailablePpaList'

export default {
  components: {
    BCard,
    BTable,
    TableSpinner,
  },
  filters: {
    toString(val) {
      return val.map(o => {
        if (typeof o === 'object') {
          return o.name
        }
        return o
      }).join(', ')
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
    }
  },
  setup() {
    const {
      fetchAvailablePpa,
      tableColumns,
    } = useAvailablePpaList()

    return {
      fetchAvailablePpa,
      tableColumns,
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
    }),
  },
  async created() {
    await this.updateTable()
  },
  methods: {
    async updateTable() {
      this.isLoading = true
      const response = await this.fetchAvailablePpa(this.applicationId)
      this.items = response
      this.isLoading = false
    },
    isArray(val) {
      return Array.isArray(val)
    },
    isBase64(val) {
      return (typeof (val) === 'string' && val.includes('data:image'))
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
.table-col {
  width: 50%;
  word-break: break-all;
}
</style>
