<template>
  <b-modal
    id="application-tags-edit"
    ref="application-tags-edit"
    centered
    title="Application Tags"
    no-close-on-backdrop
    @show="prepareData"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Selected Tags "
          >
            <b-form-tag
              v-for="tag in updatedTagNames"
              :key="tag.id"
              class="align-content-center mb-1"
              style="marginRight: 5px"
              :style="{
                backgroundColor: '#F3F2F7',
                color: tag.color,
              }"
              @remove="removeTag(tag)"
            >
              {{ tag.name }}
            </b-form-tag>
          </b-form-group>
        </b-col>

        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Select Tag Type"
          >
            <v-select
              v-model="selectedTagSet"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tagSetsList"
              :clearable="false"
              label="name"
              :placeholder="'Select Tag Type'"
              @input="resetSelectedTags"
            />
          </b-form-group>
        </b-col>

        <b-col
          v-if="selectedTagSet"
          sm="12"
          class="mb-1"
        >
          <b-form-group
            v-if="selectedTagSet.select_type == 'Multiple'"
            label="Tags"
          >
            <v-select
              v-model="selectedTagNames"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="selectedTagSet.tags"
              :clearable="false"
              label="name"
              multiple
              :placeholder="'Select Tags'"
              @option:selecting="addTagName"
              @option:deselecting="removeTag"
            />
          </b-form-group>

          <b-form-group
            v-if="selectedTagSet.select_type == 'Radio'"
            label="Tags"
          >
            <template
              v-for="tag in selectedTagSet.tags"
            >
              <b-form-radio
                :key="tag.id"
                v-model="selectedTagNames"
                :value="tag"
                name="radio-options"
                @change="addTagName"
              > {{ tag.name }}</b-form-radio>
            </template>
          </b-form-group>
        </b-col>
      </b-row>
    </b-overlay>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        class="float-right"
        :disabled="isLoading"
        @click="applyChanges"
      >
        Apply
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import { mapGetters } from 'vuex'
import {
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BOverlay,
  BButton,
  BFormTag,
  BFormRadio,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BOverlay,
    BButton,
    BFormTag,
    BFormRadio,
    vSelect,
  },
  props: {
    tagNames: {
      type: Array,
      required: true,
    },
    applicationId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      tagSetsList: [],
      selectedTagSet: {},
      selectedTagNames: null,
      updatedTagNames: [],
    }
  },
  computed: {
    ...mapGetters({
      programId: 'verticalMenu/getDefaultProgram',
    }),
  },
  watch: {
    updatedTagNames() {
      this.resetSelectedTags()
    },
  },
  methods: {
    removeTag(tag) {
      const index = this.updatedTagNames.findIndex(item => item.id === tag.id)
      this.updatedTagNames.splice(index, 1)
    },
    resetSelectedTags() {
      this.selectedTagNames = null
      if (this.selectedTagSet.select_type === 'Radio') {
        this.selectedTagNames = this.selectedTagSet.tags.find(tag => this.updatedTagNames.some(selectedTag => tag.id === selectedTag.id))
      } else if (this.selectedTagSet.select_type === 'Multiple') {
        this.selectedTagNames = this.selectedTagSet.tags.filter(tag => this.updatedTagNames.find(selectedTag => tag.id === selectedTag.id))
      }
    },
    addTagName(tag) {
      if (this.selectedTagSet.select_type === 'Radio') {
        const oldTag = this.updatedTagNames.find(item => item.tag_set_id === (this.selectedTagSet.id))
        if (oldTag) {
          this.removeTag(oldTag)
        }
      }
      if (!this.updatedTagNames.some(item => item.id === tag.id)) {
        this.updatedTagNames.push(tag)
      }
    },
    async prepareData() {
      this.updatedTagNames = this.tagNames.map(tag => ({ ...tag }))
      await this.fetchingTagSets()
    },
    async fetchingTagSets() {
      const queryParams = {
        programId: this.programId,
        hasTags: true,
        isHidden: 0,
      }
      const response = await axios.get('/auth/tag-sets', {
        params: queryParams,
      })
      this.tagSetsList = response.data.data
    },
    async applyChanges() {
      try {
        const queryParams = {
          tag_ids: this.updatedTagNames.map(tag => tag.id),
        }

        this.isLoading = true

        await axios.post(`/auth/applications/${this.applicationId}/tags`, queryParams)

        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Application Tags was successfully changed',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })

        this.$emit('tagsUpdated')
        this.closeModal()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error change Application Tags',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async closeModal() {
      this.selectedTagSet = {}
      this.selectedTagNames = null
      this.$bvModal.hide('application-tags-edit')
    },
  },
}
</script>
