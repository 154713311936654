<template>

  <!-- Table Communications Card -->
  <b-card
    no-body
  >
    <template #header>
      <h4
        class="d-flex align-items-center mb-0"
      >
        Communications
      </h4>
      <b-button
        v-if="canSendSingleCommunication"
        v-b-modal.single-communication-modal
        variant="primary"
      >
        <span>Add New</span>
      </b-button>
    </template>

    <b-table
      :items="items"
      :fields="tableColumns"
      primary-key="id"
      show-empty
      :busy="isLoading"
      empty-text="No matching records found"
      class="position-relative"
    >
      <!-- Loader -->
      <template #table-busy>
        <TableSpinner />
      </template>
      <!-- Column: trigger -->
      <template #cell(trigger)="data">
        <b-badge
          variant="light-secondary"
          class="cursor-pointer"
          @click="showDetailModal(data.item.id)"
        >
          {{ data.item.trigger }}
        </b-badge>
      </template>

      <!-- Column: Date -->
      <template #cell(date)="data">
        <span>{{ data.item.created_at | formatDate }}</span>
      </template>
    </b-table>

    <CommunicationDetailModal
      :mail-log-id="communicationDatailId"
      :can-send-single-communication="canSendSingleCommunication"
      @updateComminicationsList="setCommunactionsList"
    />

    <single-communicate-modal
      v-if="applicationId"
      :is-bulk="false"
      :marked-elements="[applicationId]"
      :filter-name="'application_id'"
      @successfullySend="communicateSuccess"
    />
  </b-card>

</template>

<script>
import { mapGetters } from 'vuex'
import {
  BBadge,
  BCard,
  BTable,
  BButton,
} from 'bootstrap-vue'

import useCommunicationList from './useCommunicationList'
import CommunicationDetailModal from '@/views/components/application-communication/communication-detail-modal/CommunicationDetailModal.vue'
import TableSpinner from '@/views/components/table-spinner/TableSpinner.vue'
import SingleCommunicateModal from '@/views/components/bulk-communicate-modal/BulkCommunicateModal.vue'
import { permissionSubjects } from '@/libs/acl/constants'

export default {
  components: {
    BBadge,
    BCard,
    BTable,
    BButton,

    TableSpinner,
    CommunicationDetailModal,
    SingleCommunicateModal,
  },
  filters: {
    formatDate(value) {
      if (value) {
        const date = new Date(value)
        const options = {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        }
        return new Intl.DateTimeFormat('en-US', options).format(date)
      }
      return ''
    },
  },
  data() {
    return {
      isLoading: false,
      items: [],
      communicationDatailId: '',
    }
  },
  setup() {
    const {
      fetchCommunication,
      tableColumns,
    } = useCommunicationList()

    return {
      fetchCommunication,
      tableColumns,
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
    }),
    canSendSingleEmailCommunication() {
      return this.$can('send', permissionSubjects.SingleEmailCommunication)
    },
    canSendSingleSmsCommunication() {
      return this.$can('send', permissionSubjects.SingleSMSCommunication)
    },
    canSendSingleCommunication() {
      return this.canSendSingleEmailCommunication || this.canSendSingleSmsCommunication
    },
  },
  async created() {
    this.setCommunactionsList()
  },
  methods: {
    showDetailModal(id) {
      this.communicationDatailId = id
      this.$bvModal.show('communication-detail-modal')
    },
    async setCommunactionsList() {
      this.isLoading = true
      const queryParams = {
        applicationId: this.applicationId,
        sortBy: 'created_at',
        sortDesc: true,
      }
      const response = await this.fetchCommunication(queryParams)
      this.items = response
      this.isLoading = false
    },
    communicateSuccess() {
      this.$bvModal.hide('single-communication-modal')
      this.setCommunactionsList()
    },
  },

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
