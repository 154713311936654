<template>
  <b-modal
    id="change-payment-status"
    ref="change-payment-status"
    centered
    title="Edit Payment Status"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="prepareData"
  >
    <Spinner
      v-if="isLoading"
    />
    <form
      v-else
      ref="form"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Payment Status"
          >
            <v-select
              id="edit-payment-status"
              v-model="newStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusesList"
              :reduce="option => option.value"
              :clearable="false"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="closeModal"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="changePaymentStatus"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  BModal, BFormGroup, BRow, BCol, BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  name: 'ApplicationChangePaymentStatusModal',
  components: {
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    Spinner,
  },
  props: {
    applicationId: {
      type: String,
      required: true,
    },
    hasPaymentFailed: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
      newStatus: '',
      statusesList: [
        {
          label: 'Payment Failed',
          value: 'failed',
        },
        {
          label: 'Payment Successful',
          value: 'successful',
        },
      ],
    }
  },
  methods: {
    prepareData() {
      this.newStatus = this.hasPaymentFailed ? 'failed' : 'successful'
    },
    async changePaymentStatus() {
      try {
        this.isLoading = true
        const queryParams = {
          has_unpaid_failed_transaction: this.newStatus === 'failed',
        }
        await axios.put(`/auth/applications/${this.applicationId}`, queryParams)
        this.$emit('paymentStatusChanged')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Application Payment Status was successfully updated',
            icon: 'ThumbsUpIcon',
            variant: 'info',
          },
        })
        this.closeModal()
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Error updating Application Payment Status',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    closeModal() {
      this.$bvModal.hide('change-payment-status')
    },
  },
}
</script>
