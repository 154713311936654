<template>
  <b-modal
    id="change-status"
    ref="change-status"
    centered
    title="Edit Status"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="prepareData"
  >
    <Spinner
      v-if="isLoading"
    />
    <form
      v-else
      ref="form"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <v-select
              id="edit-status"
              v-model="newStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="statusesList"
              :clearable="false"
              :reduce="option => option.id"
              label="name"
              value="id"
              :placeholder="selectStatusPlaceholder"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="$refs['change-status'].hide()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="selectNewStatus"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal, BFormGroup, BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  components: {
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      newStatus: '',
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
      applicationData: 'app-application-edit/getApplicationData',
      chosenApplicationStatus: 'app-application-edit/getNewApplicationStatus',
      selectStatusPlaceholder: 'app-application-edit/getStatusesLoadingStatus',
      statusesList: 'app-application-edit/getStatusesList',
      isLoading: 'app-application-edit/getIsSaveButtonDisabled',
    }),
  },
  methods: {
    prepareData() {
      this.newStatus = this.chosenApplicationStatus
    },
    async selectNewStatus() {
      if (this.applicationData.is_locked_status) {
        const payload = {
          status: this.newStatus,
          withoutCharging: false,
          vm: this,
        }
        await this.$store.dispatch('app-application-edit/updApplicationStatus', payload)
      } else {
        this.$store.commit('app-application-edit/SET_NEW_SELECTED_STATUS', this.newStatus)
        this.$store.commit('app-application-edit/SET_CONFIRMATION_CHANGE_STATUS', true)
      }
    },
  },
}
</script>
