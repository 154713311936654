<template>
  <b-modal
    id="communication-detail-modal"
    ref="communication-detail-modal"
    centered
    title="Email"
    size="lg"
    @hidden="cancel"
  >
    <Spinner
      v-if="!content"
    />
    <b-row
      v-else
    >

      <b-col
        cols="12"
        class="w-100 mb-2"
      >
        <p
          class="overflow-auto"
          v-html="content"
        />
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="w-100 mb-2"
      >
        <b-form-group
          label="Sender"
        >
          <b-form-input
            :value="sender"
            disabled
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="w-100 mb-2"
      >
        <b-form-group
          label="Recipient Email"
        >
          <b-form-input
            :value="recipient"
            disabled
          />
        </b-form-group>
      </b-col>

      <b-col
        cols="12"
        md="6"
        class="w-100 mb-2"
      >
        <b-form-group
          label="Subject"
        >
          <b-form-input
            :value="subject"
            disabled
          />
        </b-form-group>
      </b-col>

    </b-row>
    <template #modal-footer>
      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        class="mr-1"
        :disabled="isButtonDisabled || !canSendSingleCommunication"
        @click="resent"
      >
        Resend This Email
      </b-button>
      <b-button
        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
        variant="outline-secondary"
        @click="cancel"
      >
        Cancel
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BFormGroup, BFormInput, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import useEmailAuditList from '@/views/admin/email-audit/email-audit-list/useEmailAuditList'

import Spinner from '@/views/components/table-spinner/TableSpinner.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BButton,
    Spinner,
  },
  directives: {
    Ripple,
  },
  props: {
    mailLogId: {
      type: String,
      required: true,
    },
    canSendSingleCommunication: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      content: '',
      sender: '',
      recipient: '',
      subject: '',
      isButtonDisabled: false,
    }
  },
  watch: {
    mailLogId(id) {
      this.setEmailData(id)
    },
  },
  setup() {
    const {
      resentEmail,
      fetchMailLogDetail,
    } = useEmailAuditList()

    return {
      resentEmail,
      fetchMailLogDetail,
    }
  },
  methods: {
    async setEmailData(id) {
      await this.fetchMailLogDetail(id).then(response => {
        this.content = response.body
        this.sender = response.from
        this.recipient = response.to
        this.subject = response.subject
      })
    },
    async resent() {
      this.isButtonDisabled = true
      await this.resentEmail(this.mailLogId).then(() => {
        this.isButtonDisabled = false
        this.cancel()
        this.$emit('updateComminicationsList')
      })
    },
    cancel() {
      this.content = ''
      this.sender = ''
      this.recipient = ''
      this.subject = ''
      this.$bvModal.hide('communication-detail-modal')
    },
  },
}
</script>

<style lang="scss">

</style>
