import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function usePaymentContractsSetting() {
  // Use toast
  const toast = useToast()

  const createOption = queryParams => axios
    .post('/auth/payment-contract/option', queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'New Payment Contract option was successfully created',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error creating Payment Contract option',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateOption = queryParams => axios
    .put(`/auth/payment-contract/option/${queryParams.id}`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Payment Contract option was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Payment Contract option',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const deleteOption = ID => axios
    .delete(`auth/payment-contract/option/${ID}`)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success deleting Payment Contract option',
          icon: 'Trash2Icon',
          variant: 'info',
        },
      })
      return response
    })
    .catch(error => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error deleting Payment Contract option',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return error.response
    })

  const fetchPaymentMethods = queryParams => axios
    .get('/auth/payment-methods', { params: queryParams })
    .then(response => response.data.data)
    .catch(error => {
      const { response } = error
      toast({
        component: ToastificationContent,
        props: {
          title: 'Error fetching Payment Methods',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return response.status
    })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    createOption,
    updateOption,
    deleteOption,
    fetchPaymentMethods,
  }
}
