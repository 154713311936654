<template>
  <!-- Edit Sessions/Semesters -->
  <b-modal
    id="edit-sessions"
    ref="edit-sessions"
    centered
    :title="`Edit ${sessionSemester(2)}`"
    ok-title="Save Changes"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
    @show="setModalData"
    @hidden="resetModal"
    @ok="update"
  >
    <b-overlay
      id="overlay-background"
      :show="isLoading"
      variant="white"
      opacity="1"
      rounded="sm"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            :label="sessionSemester(2)"
          >
            <v-select
              v-model="selectedSessions"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="availableSessionsList"
              multiple
              label="name"
              value="id"
              :placeholder="`Select ${sessionSemester(2)}`"
            />
          </b-form-group>
          <b-form-checkbox
            v-model="keepCurrentGroups"
            class="d-flex align-items-center mb-1"
          >
            Keep current groups
          </b-form-checkbox>
          <b-form-checkbox
            v-model="recalculateBalanceWithCharge"
            class="d-flex align-items-center"
          >
            Recalculate the balance with charge
          </b-form-checkbox>
        </b-col>
        <b-col
          v-if="selectedSessions.length === 0 && errorMessage"
          sm="12"
          class="text-center text-danger"
        >
          {{ errorMessage }}
        </b-col>
      </b-row>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BOverlay,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import useEditSessions from '@/views/components/edit-sessions-modal/useEditSessions'

export default {
  components: {
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BOverlay,

    vSelect,
  },
  props: {
    programId: {
      type: String,
      default: '',
    },
    applicationId: {
      type: String,
      default: '',
    },
    sessionsList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isLoading: false,
      selectedSessions: [],
      availableSessionsList: [],
      recalculateBalanceWithCharge: false,
      keepCurrentGroups: false,
      errorMessage: '',
    }
  },
  setup() {
    const {
      fetchSessions,
      updateSessions,
    } = useEditSessions()

    return {
      fetchSessions,
      updateSessions,
    }
  },
  methods: {
    async setModalData() {
      this.isLoading = true
      this.availableSessionsList = await this.fetchSessions(this.programId).then(res => res)
      this.selectedSessions = [...this.sessionsList]
      this.isLoading = false
    },
    resetModal() {
      this.selectedSessions = []
      this.recalculateBalanceWithCharge = false
      this.keepCurrentGroups = false
      this.errorMessage = ''
    },
    update(bvModalEvt) {
      bvModalEvt.preventDefault()

      const sessions = this.selectedSessions.map(session => session.id)
      if (sessions.length === 0) {
        this.errorMessage = `The ${this.sessionSemester(2)} field is required`
        return
      }
      const queryParams = {
        session_ids: sessions,
        keep_current_groups: this.keepCurrentGroups,
        recalculate_balance_with_charge: this.recalculateBalanceWithCharge,
      }
      this.updateSessions(this.applicationId, queryParams).then(() => {
        this.$bvModal.hide('edit-sessions')
        this.$emit('updateSessions')
      })
    },
  },
}
</script>
