import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationEdit() {
  // Use toast
  const toast = useToast()

  const fetchSessions = programId => axios
    .get('/auth/sessions/', { params: { programId } })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Sessions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateSessions = (ID, queryParams) => axios
    .put(`/auth/applications/${ID}/sessions`, queryParams)
    .then(response => {
      toast({
        component: ToastificationContent,
        props: {
          title: 'Sessions was successfully updated',
          icon: 'ThumbsUpIcon',
          variant: 'info',
        },
      })
      return response.data.data
    })
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Sessions',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    fetchSessions,
    updateSessions,
  }
}
