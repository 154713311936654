import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useAvailablePpaList() {
  // Use toast
  const toast = useToast()

  // Table Handlers
  const tableColumns = [
    {
      key: 'key', label: 'ppa name', sortable: false, class: 'table-col',
    },
    {
      key: 'value', label: 'value', sortable: false, class: 'table-col',
    },
  ]

  const fetchAvailablePpa = ID => axios
    .get('/auth/program-profile-attributes', {
      params: { application_id: ID },
    })
    .then(response => response.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Available PPA items',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchAvailablePpa,
    tableColumns,
  }
}
