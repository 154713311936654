// import moment from 'moment'
import moment from '@/libs/moment'

const getFormatDate = value => (value ? moment(value, 'YYYY-MM-DD').format('MM/DD/YYYY') : '')
const setFormatDate = value => moment(value, 'MM/DD/YYYY').format('YYYY-MM-DD')
const getFormatDateTime = value => (value ? moment(value).format('MM/DD/YYYY h:mm a') : '')
const setFormatDateTime = value => moment(value, 'YYYY-MM-DD h:mm a').format()

export {
  getFormatDate,
  setFormatDate,
  getFormatDateTime,
  setFormatDateTime,
}
