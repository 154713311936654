import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useApplicationEdit() {
  // Use toast
  const toast = useToast()

  const fetchGroups = queryParams => axios
    .get('/auth/groups', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Groups',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  const updateUserGroups = (userId, queryParams) => axios
    .post(`/auth/users/${userId}/update-groups`, queryParams)
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error updating Groups',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  return {
    fetchGroups,
    updateUserGroups,
  }
}
