<template>
  <b-modal
    id="change-step-status"
    ref="change-step-status"
    centered
    title="Edit Step Status"
    ok-title="Save"
    cancel-variant="outline-secondary"
    no-close-on-backdrop
  >
    <Spinner
      v-if="isLoading"
    />
    <form
      v-else
      ref="form"
    >
      <b-row>
        <b-col
          sm="12"
          class="mb-1"
        >
          <b-form-group
            label="Status"
          >
            <v-select
              id="edit-status"
              v-model="newApplicationStepStatus"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="stepStatusesList"
              :clearable="false"
              placeholder="Select Status"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </form>

    <template #modal-footer>
      <b-button
        variant="outline-secondary"
        :disabled="isLoading"
        @click="$refs['change-step-status'].hide()"
      >
        Cancel
      </b-button>
      <b-button
        variant="primary"
        :disabled="isLoading"
        @click="selectNewStepStatus"
      >
        Save
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import {
  BModal, BFormGroup, BRow, BCol, BButton, VBModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Spinner from '@/views/components/table-spinner/TableSpinner.vue'
import useApplicationDetails from '@/views/components/application-details/useApplicationDetails'

export default {
  components: {
    BModal,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    vSelect,
    Spinner,
  },
  directives: {
    'b-modal': VBModal,
  },
  data() {
    return {
      stepStatusesList: [
        'Draft',
        'In Review',
        'Approved',
        'Rejected',
      ],
    }
  },
  setup() {
    const {
      updateStepStatus,
      postStepResults,
    } = useApplicationDetails()
    return {
      updateStepStatus,
      postStepResults,
    }
  },
  computed: {
    ...mapGetters({
      applicationId: 'app-application-edit/getApplicationId',
      updatedStep: 'app-application-edit/getUpdatedStep',
      isLoading: 'app-application-edit/getIsSaveButtonDisabled',
    }),
    newApplicationStepStatus: {
      get() {
        return this.$store.getters['app-application-edit/getNewApplicationStepStatus']
      },
      set(val) {
        this.$store.commit('app-application-edit/SET_NEW_APPLICATION_STEP_STATUS', val)
      },
    },
  },
  methods: {
    async selectNewStepStatus() {
      if (this.updatedStep) {
        this.$store.commit('app-application-edit/SET_IS_SAVE_BUTTON_DISABLED', true)

        const stepResultId = this.updatedStep?.results?.id

        if (stepResultId) {
          await this.updateStepStatus(stepResultId, this.newApplicationStepStatus)
        } else {
          const queryParams = {
            status: this.newApplicationStepStatus,
            application_id: this.applicationId,
            application_step_id: this.updatedStep.id,
            fields: [],
          }
          await this.postStepResults(queryParams)
        }
        this.$store.commit('app-application-edit/SET_IS_SAVE_BUTTON_DISABLED', false)
        this.$bvModal.hide('change-step-status')
        this.$store.commit('app-application-edit/SET_UPDATED_STEP', null)
        await this.$store.dispatch('app-application-edit/fetchApplication')
      }
    },
  },
}
</script>
