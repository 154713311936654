<template>
  <b-row class="mb-1">
    <b-col
      md="3"
    >
      <h5>
        {{ rowLabel }}
      </h5>
    </b-col>

    <b-col
      md="6"
      xl="6"
    >
      <div
        v-if="enabledField !== rowName"
        class="d-flex align-items-center text-break"
      >
        <span>{{ rowValue || 'No value' }}</span>
        <span
          v-if="canEditUserData"
          class="text-primary cursor-pointer"
          @click="enableEditModeFor"
        >
          <feather-icon
            size="16"
            icon="Edit2Icon"
            class="ml-1"
          /> Edit
        </span>
      </div>
      <div
        v-else-if="editMode && enabledField == rowName"
        class="d-flex align-items-center"
      >
        <b-form-input
          v-if="rowType == 'text'"
          :value="rowValue"
          size="sm"
          :disabled="disabled"
          :placeholder="placeholder"
          @input="updateRowValue"
        />
        <b-form-datepicker
          v-if="rowType == 'datepicker'"
          :value="rowValue"
          :max="new Date()"
          @input="updateRowValue"
        />
        <div
          class="cursor-pointer text-primary d-inline-flex ml-1"
          @click="saveFieldChanges"
        >
          <span>Apply</span>
          <feather-icon
            size="21"
            icon="CheckIcon"
          />
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BFormInput, BFormDatepicker,
} from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    BFormInput,
    BFormDatepicker,
  },
  props: {
    rowLabel: {
      type: String,
      required: true,
    },
    rowName: {
      type: String,
      required: true,
    },
    rowValue: {
      type: String,
      default: '',
    },
    rowType: {
      type: String,
      default: 'text',
    },
    enabledField: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    editMode: {
      type: Boolean,
      required: true,
    },
    canEditUserData: {
      type: Boolean,
      default: true,
    },
  },
  methods: {
    enableEditModeFor() {
      this.$emit('enableEditModeFor', this.rowName)
    },
    saveFieldChanges() {
      this.$emit('saveFieldChanges')
    },
    updateRowValue(val) {
      this.$emit('updateRowValue', { rowName: this.rowName, value: val })
    },
  },
}
</script>

<style>

</style>
