import axios from '@axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useCommunicationList() {
  // Use toast
  const toast = useToast()

  // Table Handlers
  const tableColumns = [
    { key: 'trigger', label: 'name', sortable: false },
    { key: 'date', label: 'date', sortable: false },
    { key: 'email_template_name', label: 'template', sortable: false },
  ]

  const fetchCommunication = queryParams => axios
    .get('/auth/mail-logs', { params: queryParams })
    .then(response => response.data.data)
    .catch(() => toast({
      component: ToastificationContent,
      props: {
        title: 'Error fetching Communication items',
        icon: 'AlertTriangleIcon',
        variant: 'danger',
      },
    }))

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchCommunication,
    tableColumns,
  }
}
